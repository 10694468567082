<template>
    <v-app>
         <LoadingDialog v-model="showLoadingDialog.val" />

        <!-- สำหรับเปิดเมนูบนโทรศัพท์ -->
        <v-navigation-drawer v-if="fullname.length !== 0 && $vuetify.breakpoint.mobile" app v-model="menu" temporary>
             <!-- ถ้าเลือก select HR360 System -->
            <v-list nav>
                <div  v-if="menuSystem === 0">
                    <v-list-item-group v-model="group" active-class="red--text text--accent-4">
                        <v-list-item to="MainViewWelcome">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-human-greeting</v-icon>Welcome
                            </v-list-item-title>
                        </v-list-item>
                       
                        <v-list-item v-if="hr360YourTeam === 1" to="hrEvaluate360YourTeam">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-star</v-icon>HR360 ทีมเรา
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="hr360OtherTeam === 1" to="hrEvaluate360OtherTeam">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-star</v-icon>HR360 ทีมอื่น
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="hr360OBossTeam === 1" to="hrEvaluate360BossTeam">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-star</v-icon>HR360 สำหรับผู้บริหาร
                            </v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item v-if="hr360OSumCommentFinalYour === 1" to="hrEvaluate360SumCommentYourTeamFinal">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-comment-account</v-icon>HR360 ประวัติจากทีมเรา
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="hr360OSumCommentFinalOther === 1" to="hrEvaluate360SumCommentOtherTeamFinal">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-comment-account</v-icon>HR360 ประวัติจากทีมอื่น
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="hr360OSumCommentFinalBoss === 1" to="hrEvaluate360SumCommentBossTeamFinal">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-comment-account</v-icon>HR360 ประวัติจากผู้บริหาร
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="hr360OSumScoreFinal === 1" to="hrEvaluate360SumScoreFinal">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-poll</v-icon>HR360 สรุปคะแนน
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </div>
                
            </v-list>
            
            <!-- ถ้าเลือก select Send Notification -->
            <v-list nav>
                <div  v-if="menuSystem === 1">
                    <v-list-item-group v-model="group" active-class="red--text text--accent-4">
                        <v-list-item to="MainViewWelcome">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-human-greeting</v-icon>Welcome
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="sendNotimanualHome === 1" to="main" text>
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-email-plus-outline</v-icon>ส่งข้อความ
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="sendNotimanualHistory === 1" to="historySendNoti" text>
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-history</v-icon>ประวัติการส่ง
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="sendNotimanualHistoryAll === 1" to="historySendNoti" text>
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-history</v-icon>ประวัติการส่ง
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="sendNotiAutoSetEvent === 1" to="setSendNotiAuto" text>
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-lightbulb-auto-outline</v-icon>ระบบ Auto
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="sendNotiAutoHistory === 1" to="historySendNotiAutoSummary" text>
                            <v-list-item-title>
                                 <v-icon class="mr-1">mdi-history</v-icon>ประวัติการส่ง Auto
                            </v-list-item-title>
                        </v-list-item>
    
                    </v-list-item-group>
                </div>

            </v-list>
            
            <!-- ถ้าเลือก select QuotationReport System-->
            <v-list nav>
                <div  v-if="menuSystem === 3">
                    <v-list-item-group v-model="group" active-class="red--text text--accent-4">
                        <v-list-item to="MainViewWelcome">
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-human-greeting</v-icon>Welcome
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="quotationReport === 1" to="QuotationReport" text>
                            <v-list-item-title>
                                <v-icon class="mr-1">mdi-file-document</v-icon>QuotationReport
                            </v-list-item-title>
                        </v-list-item>
                      
                    </v-list-item-group>
                </div>
            </v-list>
            
        </v-navigation-drawer>
        <!-- สำหรับเปิดเมนูบนโทรศัพท์ -->

        <!-- ตรวจสอบก่อนว่ามีการ Login หรือไม่ ถ้ามีให้แสดง เมนู -->
        <v-app-bar v-if="fullname.length !== 0" app color="#546978" dark  >
            <!-- ตรวจสอบว่าใช้บนโทรศัพท์หรือไม่ ถ้าใช่ให้แสดง icon hamburger -->
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click="menu = !menu"></v-app-bar-nav-icon>
            <!-- <v-app-bar-title>Send Notification</v-app-bar-title> -->
            
            <!-- ถ้าเลือก select HR360 System -->
            <div  v-if="menuSystem == 0">
                <!-- to คือการบอกว่าจะไปหน้าไหนได้เลย -->
                <div v-if="!$vuetify.breakpoint.mobile">
                    <v-btn to="MainViewWelcome" text>
                        <v-icon class="mr-1">mdi-human-greeting</v-icon>Welcome
                    </v-btn>

                    <v-btn v-if="hr360YourTeam === 1" to="hrEvaluate360YourTeam" text>
                        <v-icon class="mr-1">mdi-star</v-icon>HR360 ทีมเรา
                    </v-btn>
                    <v-btn v-if="hr360OtherTeam === 1" to="hrEvaluate360OtherTeam" text>
                        <v-icon class="mr-1">mdi-star</v-icon>HR360 ทีมอื่น
                    </v-btn>
                    <v-btn v-if="hr360OBossTeam === 1" to="hrEvaluate360BossTeam" text>
                        <v-icon class="mr-1">mdi-star</v-icon>HR360 สำหรับผู้บริหาร
                    </v-btn>
                    
                    <v-btn v-if="hr360OSumCommentFinalYour === 1" to="hrEvaluate360SumCommentYourTeamFinal" text>
                        <v-icon class="mr-1">mdi-comment-account</v-icon>HR360 ประวัติจากทีมเรา
                    </v-btn>
                    <v-btn v-if="hr360OSumCommentFinalOther === 1" to="hrEvaluate360SumCommentOtherTeamFinal" text>
                        <v-icon class="mr-1">mdi-comment-account</v-icon>HR360 ประวัติจากทีมอื่น
                    </v-btn>
                    <v-btn v-if="hr360OSumCommentFinalBoss === 1" to="hrEvaluate360SumCommentBossTeamFinal" text>
                        <v-icon class="mr-1">mdi-comment-account</v-icon>HR360 ประวัติจากผู้บริหาร
                    </v-btn>
                    
                    <v-btn v-if="hr360OSumScoreFinal === 1" to="hrEvaluate360SumScoreFinal" text>
                        <v-icon class="mr-1">mdi-poll</v-icon>HR360 สรุปคะแนน
                    </v-btn>
            </div>
            
               
                <!-- <v-btn v-if="sendNotiManualByUser === 1" to="sendNotiByUser" text>
                    <v-icon class="mr-1">mdi-email-plus-outline</v-icon>ส่งข้อความรายคน
                </v-btn>
                <v-btn v-if="sendNotiManualByUserHistory === 1" to="HistorySendNotiByUser" text>
                    <v-icon class="mr-1">mdi-history</v-icon>ประวัติการส่งรายคน
                </v-btn> -->
            </div>
            
            <!-- ถ้าเลือก select Send Notification -->
            <div  v-if="menuSystem == 1">
                <!-- to คือการบอกว่าจะไปหน้าไหนได้เลย -->
                <div v-if="!$vuetify.breakpoint.mobile">
                    <v-btn to="MainViewWelcome" text>
                        <v-icon class="mr-1">mdi-human-greeting</v-icon>Welcome
                    </v-btn>
                    <v-btn v-if="sendNotimanualHome === 1" to="main" text>
                        <v-icon class="mr-1">mdi-email-plus-outline</v-icon>ส่งข้อความ
                    </v-btn>
                    <v-btn v-if="sendNotimanualHistory === 1" to="historySendNoti" text>
                        <v-icon class="mr-1">mdi-history</v-icon>ประวัติการส่ง
                    </v-btn>
                    <v-btn v-if="sendNotimanualHistoryAll === 1" to="historySendNotiAll" text>
                        <v-icon class="mr-1">mdi-history</v-icon>ประวัติการส่ง
                    </v-btn>
                    <v-btn v-if="sendNotiAutoSetEvent === 1" to="setSendNotiAuto" text>
                        <v-icon class="mr-1">mdi-lightbulb-auto-outline</v-icon>ระบบ Auto
                    </v-btn>
                    <v-btn v-if="sendNotiAutoHistory === 1" to="historySendNotiAutoSummary" text>
                         <v-icon class="mr-1">mdi-history</v-icon>ประวัติการส่ง Auto
                    </v-btn>
                    
            </div>
            
               
                <!-- <v-btn v-if="sendNotiManualByUser === 1" to="sendNotiByUser" text>
                    <v-icon class="mr-1">mdi-email-plus-outline</v-icon>ส่งข้อความรายคน
                </v-btn>
                <v-btn v-if="sendNotiManualByUserHistory === 1" to="HistorySendNotiByUser" text>
                    <v-icon class="mr-1">mdi-history</v-icon>ประวัติการส่งรายคน
                </v-btn> -->
            </div>
            
            <!-- ถ้าเลือก select QuotationReport System -->
            <div v-if="menuSystem == 2">
                <v-btn to="MainViewWelcome" text>
                        <v-icon class="mr-1">mdi-human-greeting</v-icon>Welcome
                    </v-btn>
                 <v-btn v-if="quotationReport === 1" to="QuotationReport" text>
                    <v-icon class="mr-1">mdi-file-document</v-icon>QuotationReport
                </v-btn>
            </div>
            
            <v-spacer></v-spacer>
            <label>{{ fullname }}</label>
            <!-- <v-btn @click="logout()" icon>
                <v-icon>mdi-logout</v-icon>
            </v-btn> -->
            <v-icon class="ma-2 white--text" @click="logoutFromAzure()">mdi-logout</v-icon>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import APIClient from './core/BaseAPIService'
import router from "./router"
import LoadingDialog from "@/components/dialog/LoadingDialog"
import { logoutFromAzure } from "@/core/msal";

export default {
    beforeUpdate() {
        this.getFullname(), 
        this.getLevelId(),
        this.getDepartmentID(),
        this.getSendNotimanualHome(),
        this.getSendNotimanualHistory(),
        this.getSendNotimanualHistoryAll(),
        this.getSendNotiAutoSetEvent(),
        this.getSendNotiAutoHistory(),
        this.getHr360YourTeam(),
        this.getHr360OtherTeam(),
        this.getHr360BossTeam(),
        this.getHr360SumScoreFinal(),
        this.getHr360SumCommentYourTeamFinal(),
        this.getHr360SumCommentOtherTeamFinal(),
        this.getHr360SumCommentBossTeamFinal(),
        this.getSendNotiManualByUser(),
        this.getSendNotiManualByUserHistory(),
        this.getQuatationReport(),
        this.getMenuSystem()
    },
    name: "App",
    components: {
        LoadingDialog
    },
    provide() {
        return {
            loadingDialog: this.showLoadingDialog
        }
    },
    data: () => ({
        showLoadingDialog: {
            val: false
        },
        fullname: "",
        levelId: "",
        departmentID: "",
        menu: false,
        group: null,
        sendNotimanualHome: "",
        sendNotimanualHistory: "",
        sendNotimanualHistoryAll: "",
        sendNotiAutoSetEvent: "",
        sendNotiAutoHistory: "",
        hr360YourTeam: "",
        hr360OtherTeam: "",
        hr360OBossTeam: "",
        hr360OSumScoreFinal: "",
        hr360OSumCommentFinalYour: "",
        hr360OSumCommentFinalOther: "",
        hr360OSumCommentFinalBoss: "",
        sendNotiManualByUser: "",
        sendNotiManualByUserHistory: "",
        quotationReport: "",
        menuSystem: "",
    }),
    watch: {
        group() {
            this.menu = false
        }
    },
    methods: {
        async logoutFromAzure() {
              try {
                sessionStorage.clear();
                router.push({ name: "LoginView" }).then(() => {
                  location.reload();
                });
              } catch (error) {
                console.error(error);
              }
            },
        logout() {
            APIClient()
                .get("/logout")
                .then(res => {
                    sessionStorage.clear() //ถ้าไม่เคลียร์ก็จะยัง url อื่นๆ ได้อยู่นะจ๊ะ
                    console.log(res)
                    location.reload()
                    router.push({ name: "LoginView" })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getFullname() {
            this.fullname = sessionStorage.getItem("user")
            if (this.fullname != null) {
                let myobj = JSON.parse(this.fullname)
                this.fullname = myobj.fullname
            }
        },
        getLevelId() {
            this.levelId = sessionStorage.getItem("user")
            if (this.levelId != null) {
                let myobj = JSON.parse(this.levelId)
                this.levelId = myobj.levelId
            }
        },
        getDepartmentID() {
            this.departmentID = sessionStorage.getItem("user")
            if (this.departmentID != null) {
                let myobj = JSON.parse(this.departmentID)
                this.departmentID = myobj.departmentID
            }
        },
        getSendNotimanualHome() {
            this.sendNotimanualHome = sessionStorage.getItem("user")
            if (this.sendNotimanualHome != null) {
                let myobj = JSON.parse(this.sendNotimanualHome)
                this.sendNotimanualHome = myobj.sendNotimanualHome
            }
        },
        getSendNotimanualHistory() {
            this.sendNotimanualHistory = sessionStorage.getItem("user")
            if (this.sendNotimanualHistory != null) {
                let myobj = JSON.parse(this.sendNotimanualHistory)
                this.sendNotimanualHistory = myobj.sendNotimanualHistory
            }
        },
        getSendNotimanualHistoryAll() {
            this.sendNotimanualHistoryAll = sessionStorage.getItem("user")
            if (this.sendNotimanualHistoryAll != null) {
                let myobj = JSON.parse(this.sendNotimanualHistoryAll)
                this.sendNotimanualHistoryAll = myobj.sendNotimanualHistoryAll
            }
        },
        getSendNotiAutoSetEvent() {
            this.sendNotiAutoSetEvent = sessionStorage.getItem("user")
            if (this.sendNotiAutoSetEvent != null) {
                let myobj = JSON.parse(this.sendNotiAutoSetEvent)
                this.sendNotiAutoSetEvent = myobj.sendNotiAutoSetEvent
            }
        },
        getSendNotiAutoHistory() {
            this.sendNotiAutoHistory = sessionStorage.getItem("user")
            if (this.sendNotiAutoHistory != null) {
                let myobj = JSON.parse(this.sendNotiAutoHistory)
                this.sendNotiAutoHistory = myobj.sendNotiAutoHistory
            }
        },
        getHr360YourTeam() {
            this.hr360YourTeam = sessionStorage.getItem("user")
            if (this.hr360YourTeam != null) {
                let myobj = JSON.parse(this.hr360YourTeam)
                this.hr360YourTeam = myobj.hr360YourTeam
            }
        },
        getHr360OtherTeam() {
            this.hr360OtherTeam = sessionStorage.getItem("user")
            if (this.hr360OtherTeam != null) {
                let myobj = JSON.parse(this.hr360OtherTeam)
                this.hr360OtherTeam = myobj.hr360OtherTeam
            }
        },
        getHr360BossTeam() {
            this.hr360OBossTeam = sessionStorage.getItem("user")
            if (this.hr360OBossTeam != null) {
                let myobj = JSON.parse(this.hr360OBossTeam)
                this.hr360OBossTeam = myobj.hr360OBossTeam
            }
        },
        getHr360SumScoreFinal() {
            this.hr360OSumScoreFinal = sessionStorage.getItem("user")
            if (this.hr360OSumScoreFinal != null) {
                let myobj = JSON.parse(this.hr360OSumScoreFinal)
                this.hr360OSumScoreFinal = myobj.hr360OSumScoreFinal
            }
        },
        getHr360SumCommentYourTeamFinal() {
            this.hr360OSumCommentFinalYour = sessionStorage.getItem("user")
            if (this.hr360OSumCommentFinalYour != null) {
                let myobj = JSON.parse(this.hr360OSumCommentFinalYour)
                this.hr360OSumCommentFinalYour = myobj.hr360OSumCommentFinalYour
            }
        },
        getHr360SumCommentOtherTeamFinal() {
            this.hr360OSumCommentFinalOther = sessionStorage.getItem("user")
            if (this.hr360OSumCommentFinalOther != null) {
                let myobj = JSON.parse(this.hr360OSumCommentFinalOther)
                this.hr360OSumCommentFinalOther = myobj.hr360OSumCommentFinalOther
            }
        },
        getHr360SumCommentBossTeamFinal() {
            this.hr360OSumCommentFinalBoss = sessionStorage.getItem("user")
            if (this.hr360OSumCommentFinalBoss != null) {
                let myobj = JSON.parse(this.hr360OSumCommentFinalBoss)
                this.hr360OSumCommentFinalBoss = myobj.hr360OSumCommentFinalBoss
            }
        },
        getSendNotiManualByUser() {
            this.sendNotiManualByUser = sessionStorage.getItem("user")
            if (this.sendNotiManualByUser != null) {
                let myobj = JSON.parse(this.sendNotiManualByUser)
                this.sendNotiManualByUser = myobj.sendNotiManualByUser
            }
        },
        getSendNotiManualByUserHistory() {
            this.sendNotiManualByUserHistory = sessionStorage.getItem("user")
            if (this.sendNotiManualByUserHistory != null) {
                let myobj = JSON.parse(this.sendNotiManualByUserHistory)
                this.sendNotiManualByUserHistory = myobj.sendNotiManualByUserHistory
            }
        },
        getQuatationReport() {
            this.quotationReport = sessionStorage.getItem("user")
            if (this.quotationReport != null) {
                let myobj = JSON.parse(this.quotationReport)
                this.quotationReport = myobj.quotationReport
            }
        },
        getMenuSystem() {
            this.menuSystem = sessionStorage.getItem("user")
            if (this.menuSystem != null) {
                let myobj = JSON.parse(this.menuSystem)
                this.menuSystem = myobj.menuSystem
            }
        },
        goMain() {
            router.push({ name: "main" })
        },
        goHistorySendNoti() {
            router.push({ name: "historySendNoti" })
        },
        goHistorySendNotiAll() {
            router.push({ name: "historySendNotiAll" })
        },
        goSetNotiAuto() {
            router.push({ name: "setSendNotiAuto" })
        },
        goHistorySendNotiAuto() {
            router.push({ name: "historySendNotiAuto" })
        },
        goHrEvaluate360YourTeam() {
            router.push({ name: "hrEvaluate360YourTeam" })
        },
        goHrEvaluate360OtherTeam() {
            router.push({ name: "hrEvaluate360OtherTeam" })
        },
        goHrEvaluate360BossTeam() {
            router.push({ name: "hrEvaluate360BossTeam" })
        },
        goHrEvaluate360SumScoreFinal() {
            router.push({ name: "hrEvaluate360SumScoreFinal" })
        },
        goHrEvaluate360SumCommentYourTeamFinal() {
            router.push({ name: "hrEvaluate360SumCommentYourTeamFinal" })
        },
        goHrEvaluate360SumCommentOtherTeamFinal() {
            router.push({ name: "hrEvaluate360SumCommentOtherTeamFinal" })
        },
        goHrEvaluate360SumCommentBossTeamFinal() {
            router.push({ name: "hrEvaluate360SumCommentBossTeamFinal" })
        },
        goQuatationReport() {
            router.push({ name: "QuotationReport" })
        },
        goSendNotiManualByUser() {
            router.push({ name: "sendNotiByUser" })
        },
        goSendNotiManualByUserHistory() {
            router.push({ name: "HistorySendNotiByUser" })
        },
    }
}
</script>
