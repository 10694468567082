import axios from "axios";

const BASE_FULL_URL = process.env.VUE_APP_BASE_API || "/api";

export default (accessToken) => {
  const defaultOptions = {
    baseURL: BASE_FULL_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);
  instance.interceptors.request.use(config => {
    const keys = JSON.parse(sessionStorage.getItem(`msal.token.keys.${process.env.VUE_APP_AZURE_AD_CLIENT_ID}`));
    const token = JSON.parse(sessionStorage.getItem(keys.idToken[0]));
    if (token) {
      config.headers.Authorization = `Bearer ${token.secret}`;
    }
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config
  })

  return instance;
};