<template>
    <v-main>
        <v-container fill-height>
          <v-card class="mx-auto d-flex flex-column col-sm-6">
            <v-card-title class="display-1 align-self-center">SendNoti And HR360 System</v-card-title>
    
            <v-card-text>
              <v-alert type="error" v-if="error" dense text>{{ error }}</v-alert>
              <v-select v-model="menuSystem" :items="itemSystem" item-value="itemSystemId" :item-text="(item) => item.itemSystemName" label="Select System" outlined dense></v-select>
              <v-btn @click="loginWithAzure()" :loading="btnLoginLoading" outlined block>เข้าสู่ระบบด้วย Azure AD</v-btn>
            </v-card-text>
          </v-card>
        </v-container>
    </v-main>
</template>

<script>
import APIClient from '../core/BaseAPIService'
import router from "../router"
import { loginWithAzure } from "@/core/msal";

const QUOTATION = "?s=quotation"

export default {
    name: "LoginView",
    created() {
        sessionStorage.clear();
        this.menuSystem = parseInt(localStorage.getItem("menuSystem"))
    },
    data() {
        return {
            username: "",
            password: "",
            showPassword: false,
            itemSystem: [
                {
                    itemSystemId: 0,
                    itemSystemName: "HR360 System"
                },
                {
                  "itemSystemId": 1,
                  "itemSystemName": "Send Notification System"
                }
                // {
                //   "itemSystemId": 2,
                //   "itemSystemName": "QuotationReport System"
                // }
                ],
            menuSystem: 0,
            btnLoginLoading: false,
            error: null,
        }
    },
    beforeCreate() {
        let currentURL = location.href

        if (currentURL.includes(QUOTATION)) {
            localStorage.setItem("menuSystem", 2)
        } else {
            localStorage.setItem("menuSystem", 0)
        }
    },
    methods: {
        async loginWithAzure() {
          this.error = null;
          this.btnLoginLoading = true;
          try {
            const loginResponse = await loginWithAzure();
            await APIClient(loginResponse.idToken)
              .get("/user/info", {
                params: {
                    menuSystem: this.menuSystem
                }
             })
              .then((response) => {
                sessionStorage.setItem("user", JSON.stringify(response.data.result));
                router.push({ name: "MainViewWelcome" });
              })
              .catch((err) => {
                this.error = err.response.data.message;
              });
          } catch (error) {
            console.error(error);
          } finally {
            this.btnLoginLoading = false;
          }
    },
        async login() {
            const payload = {
                username: this.username,
                password: this.password,
                menuSystem: this.menuSystem
            }

            await APIClient()
                .post("/login", payload)
                .then(res => {
                    console.log(res)

                    let user = {
                        userId: res.data.userId,
                        username: res.data.username,
                        fullname: res.data.fullname,
                        levelId: res.data.levelID,
                        departmentID: res.data.departmentID,
                        sendNotimanualHome: res.data.sendNotimanualHome,
                        sendNotimanualHistory: res.data.sendNotimanualHistory,
                        sendNotimanualHistoryAll: res.data.sendNotimanualHistoryAll,
                        sendNotiAutoSetEvent: res.data.sendNotiAutoSetEvent,
                        sendNotiAutoHistory: res.data.sendNotiAutoHistory,
                        hr360YourTeam: res.data.hr360YourTeam,
                        hr360OtherTeam: res.data.hr360OtherTeam,
                        hr360OBossTeam: res.data.hr360OBossTeam,
                        hr360OSumScoreFinal: res.data.hr360OSumScoreFinal,
                        sendNotiManualByUser: res.data.sendNotiManualByUser,
                        sendNotiManualByUserHistory: res.data.sendNotiManualByUserHistory,
                        hr360OSumCommentFinalYour: res.data.hr360OSumCommentFinalYour,
                        hr360OSumCommentFinalOther: res.data.hr360OSumCommentFinalOther,
                        hr360OSumCommentFinalBoss: res.data.hr360OSumCommentFinalBoss,
                        quotationReport: res.data.quotationReport,
                        menuSystem: payload.menuSystem
                    }
                    // ถ้าเลือก select Notification System แต่ไม่มีสิทธิเห็นเมนูนี้
                    if (user.menuSystem == 1 && user.sendNotimanualHome == 0) {
                        alert("คุณไม่มีสิทธิเข้าระบบ Notification System")
                    }
                    // ถ้าเลือก select QuotationReport System แต่ไม่มีสิทธิเห็นเมนูนี้ (ไม่ใช่เซลล์)
                    else if (user.menuSystem == 2 && user.quotationReport == 0) {
                        alert("คุณไม่มีสิทธิเข้าระบบ QuotationReport System")
                    } else {
                        sessionStorage.setItem("Access-Token", res.data.token)
                        sessionStorage.setItem("user", JSON.stringify(user))
                        router.push({ name: "MainViewWelcome" })
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    alert("Username or Password Wrong!")
                })
        }
    }
}
</script>
<style scoped>
.v-main {
    height: 100%;
    padding: 0px 0px 0px 0px;
    background: radial-gradient(circle, #90a0ac 0%, #546978 100%) !important;
}

</style>
